import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { convertToDevanagari } from "../helpers/utility";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col md={6}>
            <p className="text-center text-md-start mb-0">
              कॉपीराइट &copy; {convertToDevanagari(new Date().getFullYear())} वीरशैव हितसंवर्धक मंडळ, यवतमाळ
            </p>
          </Col>
          <Col md={6}>
            <p className="powered-by-text text-center text-md-end mb-0">
              Created by{" "}
              वीरशैव हितसंवर्धक मंडळ, यवतमाळ
            </p>
          </Col>
          <Col md={12}>
            <ul className="d-md-flex mt-4 justify-content-center text-center footer-links">
              <li><Link className="mx-3" to="/terms-conditions">Terms and Conditions</Link></li>
              <li><Link className="mx-3" to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link className="mx-3" to="/pricing">Pricing</Link></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
