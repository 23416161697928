import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageTitle from "../../components/PageTitle";

const Pricing = () => {
  return (
    <>
      <PageTitle title="Pricing" />
      <section>
        <Container>
          <Row style={{backgroundColor: '#ffffff', padding: '3rem 1rem'}} className="justify-content-center">
            <Col md={8}>
            <h4 className="section-title">
              Pricing
              </h4>
              
              <p>
                The registration fee for the 'Vadhu-Var Parichay Melawa' is <b>Rs.300/-</b> only. You will get registered and your profile will be shown in the information booklet that LingayatYavatmal.com will publish. The information booklet will be sent to your respective address.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Pricing;
