import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Form,
  Button
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../redux/reducers/user/user.thunk";
import PageTitle from "../components/PageTitle";
import { displayErrorMessages } from "../helpers/diplayError";
import Loader from "../shared-components/loader";

const Registration = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  const dispatch = useDispatch();
  const { user, errorMessage } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    clearAuthErrDiv();
    dispatch(registerUser(data, props));
  };

  const clearAuthErrDiv = () => {
    let authErr = document.querySelector("#authErr");
    authErr.innerHTML = "";
  };

  const successMessage = (successMessage) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<div class="alert alert-success">' +
            " " +
            successMessage +
            "</div>",
        }}
      />
    );
  };

  return (
    <>
      <PageTitle title="नोंदणी" align="center" />
      <section>
        {loading && <Loader />}
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="bg-white p-4 mb-3 vh_instructions">
                <h3>नोंदणी करण्याआधी महत्वाची सूचना</h3>
                <ul>
                  <li><strong>तुमच्या प्रोफाइल चा Username म्हणजेच तुमचे नाव आणि Password म्हणजेच तुमचा WhatsApp नं. लक्षात ठेवणे आवश्यक आहे.</strong></li>
                  <li>स्टार (<span className="vh-asterisk">*</span>) केलेली माहिती देणे आवश्यक आहे</li>
                  <li>मोबाइल मध्ये मराठी टायपिंग करीता <a href="https://www.youtube.com/watch?v=w9Lo7wh2jI0" target="_blank" rel="no-opener noreferrer">इथे क्लिक करा</a></li>
                  <li>कॉम्पुटर मध्ये मराठी टायपिंग करीता <a href="https://www.google.com/intl/sa/inputtools/try/" target="_blank" rel="no-opener noreferrer">इथे क्लिक करा</a>. सविस्तर माहिती करिता&nbsp;
                  <a href="https://www.youtube.com/watch?v=C7zx6TrTkQY&t=4s" target="_blank" rel="no-opener noreferrer">इथे क्लिक करा</a></li>
                </ul>
              </div>
              <div id="authErr"></div>
              <div className="bg-white">
                {user != null
                  ? successMessage(user.message)
                  : errorMessage != null
                  ? displayErrorMessages(
                      errorMessage.error,
                      document.getElementById("authErr")
                    )
                  : errorMessage}
              </div>
              <Form onSubmit={handleSubmit(onSubmit)} className="vh-form">
                <h2 className="form-title">नोंदणी फी रु.४००/-</h2>
                <Form.Group className="mb-3" controlId="username">
                  <Form.Label>
                    तुमचे नाव (Username) <span className="vh-asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="उदा. आशिष"
                    {...register("username", {
                      required: true,
                      // pattern: /^[\u0900-\u097F ,-/()]+$/,
                      pattern: /^[\u0900-\u097F ]+$/,
                    })}
                  />
                  <p className="vh-error">
                    {errors.username?.type === "required" && "नाव आवश्यक आहे"}
                  </p>
                  <p className="vh-error">
                    {errors.username?.type === "pattern" &&
                      "फक्त मराठी स्वीकारली जाईल"}
                  </p>
                </Form.Group>

                <Form.Group className="mb-3" controlId="mobile">
                  <Form.Label>
                    तुमचा WhatsApp नं. (Password)<span className="vh-asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="उदा. १२८३९२८३९२"
                    {...register("mobile", {
                      required: true,
                      pattern: /^[\u0966-\u096F]+$/,
                      maxLength: 10,
                    })}
                  />
                  <p className="vh-error">
                    {errors.mobile?.type === "required" &&
                      "मोबाइल नं. आवश्यक आहे"}
                  </p>
                  <p className="vh-error">
                    {errors.mobile?.type === "pattern" &&
                      "फक्त मराठी अंक स्वीकारले जाईल"}
                  </p>
                  <p className="vh-error">
                    {errors.mobile?.type === "maxLength" &&
                      "कृपया वैध मोबाइल नं. टाईप करा"}
                  </p>
                </Form.Group>

                <input type="hidden" id="password" name="password" value="vmhy" />

                <Button variant="danger" type="submit" className="w-100">
                नोंदणी करा
                </Button>
                <div className="mt-3 vh-already">
                  <p>
                    आधीच नोंदणी केली आहे?{" "}
                    <Link to="/login">येथे लॉगिन करा</Link>
                  </p>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Registration;