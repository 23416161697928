import axios from "axios";
import Urls from "../constants/urls";

export const apiClient = () => {

  let api_token = null;
  const API_URL = Urls.API_BASE_URL;

  api_token = localStorage.getItem("user-token");

  let headers = {
    Accept: "application/json",
  };

  if (api_token) {
    headers.Authorization = `Bearer ${api_token}`;
  }

  const axiosInstance = axios.create({
    baseURL: API_URL,
    timeout: 31000,
    headers: headers,
  });

  return axiosInstance;
};

export default apiClient;
