import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageTitle from "../../components/PageTitle";

const About = () => {
  return (
    <>
      <PageTitle title="आमच्या विषयी" />
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="bg-white p-4 mb-4 vh_about_section">
                <h4 className="section-title">
                  वीरशैव हितसंवर्धक मंडळाचा ईतिहास
                </h4>
                <img
                  src="https://lingayatyavatmal.com/assets/img/satkar.jpg"
                  className="img-fluid mb-3"
                  alt=""
                />
                <img
                  src="https://lingayatyavatmal.com/assets/img/satkar02.jpg"
                  className="img-fluid mb-3"
                  alt=""
                />
                <p className="mb-0">
                  वीरशैव हितसंवर्धक मंडळाची स्थापना १९४८ साली बसवेश्‍वर
                  जयंतीच्या दिवशी करण्यात आली. मंडळाचे पहिले अध्यक्ष म्हणून
                  श्री.नारायणराव देमापूरे यांची तर मानाधिष्ठित अध्यक्ष म्हणून
                  श्री.अण्णासाहेब देशमुख जरंगकर यांची निवड करण्यात आली होती. या
                  मंडळात श्री.शंकरराव शेटे, लहानुआप्पाजी गाढवे श्री.ओंकार आप्पा
                  दिवे, श्री.नारायणराव डबरे, श्री.विश्‍वासराव देशमुख,श्री.खडक्कर
                  गुरुजी, श्री.नारायण कावळे, श्री.महादेवराव हातगांवकर,
                  श्री.मनोहरराव हातगावकर, श्री.दादा गुळकरी, श्री.नारायणराव
                  हमदापुरे, श्री.विश्वासराव तिजारे, श्री.दिगांबरराव डोंगरे,
                  इ.मंडळी होती. त्या काळातही विद्यार्थ्यांना शिष्यवृत्ती
                  वाटण्यात येत होती.
                </p>
              </div>

              <div className="bg-white p-4 mb-4 vh_about_section">
                <h4 className="section-title">
                  वीरशैव हितसंवर्धक मंडळ, यवतमाळ द्वारा महात्मा बसवेश्‍वर
                  सांस्कृतिक भवन यवतमाळ ची निर्मिती
                </h4>
                <img
                  src="https://lingayatyavatmal.com/assets/img/bhavan.jpg"
                  className="img-fluid mb-3"
                  alt=""
                />
                <p className="mb-0">
                  सांस्कृतिक भवनासाठी लिंगैक्य श्री.लहानुआप्पाजी गाढवे यांनी दहा
                  हजार चौ.फू.प्लॉटचे दान दिले आणि लि.श्री.सुधाकरराव गाढवे
                  ह्यांच्या अथक प्रयत्नांनी २० नोव्हे. २०१० ला म.बसवेश्‍वर
                  सांस्कृतिक भवन बांधून पूर्ण केले हे दोघेही प्रस्तुत सांस्कृतिक
                  भवनाचे शिल्पकार आहेत. त्यांच्या पावन स्मृतिला विनम्र अभिवादन.
                </p>
              </div>

              <div className="bg-white p-4 mb-4 vh_about_section">
                <h4 className="section-title">
                  महात्मा बसवेश्‍वर सांस्कृतिक भवनाची उभारणी
                </h4>
                <img
                  src="https://lingayatyavatmal.com/assets/img/drpuja.jpg"
                  className="img-fluid mb-3"
                  alt=""
                />
                <p>
                  म.बसवेश्‍वर भवनाची उभारणी श्री.लहानुआप्पाजी गाढवे यांनी दान
                  दिलेल्या जागेवर करावी ह्यासंबंधीचा विचार व मानसिक तयारी तशी
                  इ.स. २००० पासूनच सुरु झाली होती. महात्मा बसवेश्‍वर भवनाचे
                  भुमीपुजन आणि उद्घाटन{" "}
                  <b>श्री श्री जगद्गुरू डॉ.चंद्रशेखर महास्वामी</b> ह्यांचे हस्ते
                  झाले, मंडळाने या आधी शिंदे नगर येथे घेतलेला प्लॉट धर्मादाय
                  आयुक्त अमरावती यांची पूर्व परवाणगी घेऊन ती जागा विकण्यात आली.
                </p>
                <img
                  src="https://lingayatyavatmal.com/assets/img/home-puja.jpg"
                  className="img-fluid mb-3"
                  alt=""
                />
                <p>
                  कार्यकरिणीच्या सदस्यांनी यवतमाळ शहरात आठवड्यातून काही दिवस सतत
                  फिरुन वर्गणी गोळा करण्याचे अभियान आरंभले. पाच हजार रुपये कमीत
                  कमी एका घराने द्यावे असे आवाहन केल्या गेले. यवतमाळ जिल्ह्या
                  नंतर,विदर्भातील सर्वच जिल्ह्यातुन उत्तम प्रतिसाद मिळत गेला.
                  उदगीरचे श्री.प्रा.राजेंद्र जिरोबे यांनी दुभाषीचे काम पाहिले
                  त्यांच्यासह कर्नाटकातील काही जिल्ह्यात फिरले. कार्यकर्ते
                  सपाटून व झपाटून बांधकाम निधी गोळा करण्याच्या कार्याला लागले.
                  जेथे जेथे कुणाचे नातेवाईक आहेत त्या-त्या गावी कार्यकर्त्यांनी
                  स्वतःच्या खर्चाने दौरे केले. समाजबांधवांनीही उत्तम वागणूक व
                  प्रतिसाद दिला त्यातूनच आजची ही वास्तू उभी राहू शकली. याचे सर्व
                  श्रेय वीरशैव-लिंगायत समाजबांधवांनाच आहे हे कृतज्ञपणे सांगितले
                  पाहिजे. महाराष्ट्र, मध्यप्रदेश, कर्नाटक, आंध्र येथील
                  गावोगावीच्या समाजबांधवांनी दिलेल्या अपार प्रेमामुळेच महात्मा
                  बसवेश्‍वर सांस्कृतिक भवनाची भव्य वास्तु आज उभी आहे. आपण सर्व
                  या ऐतिहासिक घटनेचे साक्षीदार आहातच.या सर्व गोष्टी घडत असतांनाच
                  आमच्या खांद्याला खांदा लावुन काम करणारे <b>वसुधा
                  प्रतिष्ठाण, लिंगायत महिला मंडळ</b> ह्यांचे योगदानही न विसरण्या
                  सारखे आहे.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default About;
