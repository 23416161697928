import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import PageTitle from "../../components/PageTitle";

const ThankYou = () => {
  return (
    <>
      <PageTitle title="धन्यवाद" align="center" />
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="success-container text-center">
                <img src="https://lingayatyavatmal.com/assets/img/thank-you.png" alt="Thank you" />
                <h4 className="section-title">धन्यवाद!</h4>
                <p><b>आपण यशस्वीरित्या नोंदणी केली आहे</b></p>
                <p>मेळाव्या बद्दलच्या अद्यवत माहिती करीता निलेश शेटे (९३७२६१२२७०) ह्यांचा WhatsApp नंबर  सेव्ह करून  उपवर/उपवधू  यांचे  नावासह नोंदणी झाली आहे असा WhatsApp मेसेज करणे आवश्यक आहे.</p>
                <Link to="/my-profile" className="btn btn-danger">तुमचा प्रोफाइल इथे पाहा</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ThankYou;
