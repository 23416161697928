const URLs = {
  APP_URL: 'https://lingayatyavatmal.com/',
  API_BASE_URL: 'https://lingayatyavatmal.com/backend/api/',
  IMAGE_BASE_URL: 'https://lingayatyavatmal.com/backend/uploads',
  
  RAZORPAY_API_KEY: 'rzp_live_njIlkrNaGmZMI5',
  // RAZORPAY_API_KEY: 'rzp_test_7V5lsZimnRzEiq'

  ADMIN_URL: 'https://lingayatyavatmal.com/admin',
  ADMIN_API_BASE_URL: 'https://lingayatyavatmal.com/backend/admin/',
};

export default URLs;

// const Urls = {
//   APP_URL: 'https://localhost:3000/',
//   API_BASE_URL: 'https://localhost:8000/api/',
//   IMAGE_BASE_URL: 'https://localhost:8000/uploads',
  
//   // RAZORPAY_API_KEY: 'rzp_test_7V5lsZimnRzEiq',
//   RAZORPAY_API_KEY: 'rzp_live_njIlkrNaGmZMI5'
// };

// export default Urls;