import React from "react";
import {
  Col,
  Container,
  Row,
} from "react-bootstrap";
import PageTitle from "../../components/PageTitle";

const Contact = () => {
  return (
    <>
      <PageTitle title="संपर्क" />
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="bg-white p-4 mb-3">
                <h4 className="vh-contact-title">दूरध्वनी क्र.</h4>
                <p>
                  निलेश शेटे - ९३७२६१२२७०
                  <br />
                  अभय देमापुरे - ७०२८०९४४४२
                </p>
              </div>
              {/* <div className="bg-white p-4 mb-3">
                <h4 className="vh-contact-title">ई-मेल</h4>
                <p>enquiry@lingayatyavatmal.com</p>
              </div> */}
              <div className="bg-white p-4 mb-3">
                <h4 className="vh-contact-title">पत्ता</h4>
                <p>
                  महात्मा बसवेश्वर सांस्कृतिक भवन,
                  <br />
                  दारव्हा रोड, लोहारा,
                  <br />
                  यवतमाळ - ४४५००१
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contact;
