import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Col, Container, Row, Form, Button, Modal, ProgressBar } from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import Loader from "../../shared-components/loader";
import upload from "../../assets/images/upload.png";
import apiClient from "../../helpers/apiClient";
import toasty from "../../utilities/toasty";
import Urls from "../../constants/urls";

const UploadImage = (props) => {

  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [disable, setDisable] = useState(true);
  const [imagePath, setImagePath] = useState(upload);
  const [progress, setProgress] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    apiClient().get("/user-details/" + id)
        .then((response) => {
          response.data.data.photo !== null && setImagePath(`${Urls.IMAGE_BASE_URL}/${response.data.data.photo}`);
          response.data.data.photo !== null && setIsEdit(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
  }, [id]); 

  const isFileImage = (file) => {
    return file && file['type'].split('/')[0] === 'image';
  }

  const handleUploadChange = (e) => {
    try {
      if(Math.round(e.target.files[0].size / 1024 / 1024) < 1.5) {
        if(isFileImage(e.target.files[0])) {
          setImagePath(URL.createObjectURL(e.target.files[0]));
          setDisable(false);
        } else {
          toasty('फक्त इमेज फाईल अपलोड करणे.', 'error');
        }
      } else {
        toasty('फोटोची साइझ १.५Mb पेक्षा जास्त नको.', 'error');
      }
      setModalShow(false);
    } catch(e) {
      
    }
  }

  // form submit
  const onSubmit = (data) => {
    setLoading(true);
    if(data.photo !== undefined) {
      if(data.photo[0]!= null) {
        const fd = new FormData();
        fd.append("photo", data.photo[0]);
  
        const options = {
          onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
  
            if(percent < 100) {
              setProgress(percent);
            }
          }
        }      
  
        apiClient().post("/upload-image/" + id, fd, options)
          .then((response) => {
            if (response.status === 200) {
              setProgress(100);
              setTimeout(() => {
                setProgress(0);
                setLoading(false);
                if(isEdit) {
                  props.history.push('/my-profile');
                } else {
                  props.history.push('/edit-details/' + id);
                }
                toasty('आपला फोटो अपलोड झाला आहे.', 'success');
              }, 1000);
            }
          })
          .catch((error) => {
              toasty('क्षमस्व! आपला फोटो अपलोड नाही होऊ शकत.', 'error');
              setLoading(false);
          });
      } 
    } else {
      if(isEdit) {
        props.history.push('/my-profile');
      } else {
        props.history.push('/edit-details/' + id);
      }
    }
  }
  
  return (
    <>
    <PageTitle title="प्रोफाइल नोंदणी" align="center" />
    {loading && <Loader />}
    <section>
      <Container>
        <Row className="justify-content-center">
          <Col xl={9}>
            <div className="bg-white p-4 mb-3 vh_instructions">
              <h3>नोंदणी करण्याआधी काही महत्वाच्या सूचना</h3>
              <ul>
                <li>स्टार (<span className="vh-asterisk">*</span>) केलेली माहिती देणे आवश्यक आहे</li>
                <li>नावा नंतर स्पेस देऊ नये</li>
                <li><img src="https://lingayatyavatmal.com/assets/img/photo-size.jpg" style={{'width':'112px'}} alt="" /> अपलोड केलेल्या फोटो मध्ये तुमचा चेहरा बरोबर दिसत आहे ते चेक करा. <b>पासपोर्ट</b> साईझ फोटो अपलोड करा.</li>
                <li>वार्षिक उत्पन्न टाकताना ते <b>५०.५ हजार</b> किंवा <b>४.५ लाख</b> याच फॉरमॅट मध्ये टाका</li>
              </ul>
            </div>
            
            <Form onSubmit={handleSubmit(onSubmit)} className="vh-form" encType="multipart/form-data">

              <Row className="mb-3">
                <Form.Group as={Col} style={{'borderBottom':'1px solid #d7d7d7'}}>
                  <Form.Label style={{'color':'#d4007f'}}>उप वधु / वर यांचा फोटो</Form.Label>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <div className="upload-image-control">
                  <img src={imagePath} onClick={() => setModalShow(true)}  alt="फोटो अपलोड करण्यासाठी इथे क्लिक करा" className="img-fluid upload-img" />
                </div>
                { progress > 0 ? <ProgressBar animated now={progress} label={`${progress}%`} /> : null}
              </Row>

              <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                centered
              >
                <Modal.Body>
                  <div className="modal-content">
                    <h3>महत्वाची सूचना</h3>
                    <img src="https://lingayatyavatmal.com/assets/img/photo-size.jpg" style={{'width':'112px'}} alt="" /> 
                    <p><b>पासपोर्ट</b> साईझ फोटो अपलोड करा. <b>फोटोची साइझ १.५Mb पेक्षा जास्त नको.</b></p>
                    <Form.Group controlId="photo">
                      <Form.Label style={{cursor: "pointer"}}>
                        <Button
                          variant="danger"
                          className="w-100 mx-auto"
                          style={{ maxWidth: "290px", pointerEvents: "none" }}
                        >
                          फोटो अपलोड करा
                        </Button>
                      </Form.Label>
                      <Form.Control 
                        type="file"
                        hidden
                        onInputCapture={handleUploadChange} 
                        {...register("photo")} />
                    </Form.Group>
                  </div>
                </Modal.Body>
              </Modal>

              <Form.Group className="mb-3">
                <Form.Label>नोटिस</Form.Label>
                <div></div>
                <Form.Text className="text-muted">
                  नोंदणी फार्म मध्ये लिहिलेली माहिती पूर्णपणे खरी आहे. माहिती
                  चुकीची आढळल्यास त्याकरिता पालक स्वत: जबाबदार राहतील, मंडळ
                  जबाबदार राहणार नाही.
                </Form.Text>
              </Form.Group>

              { isEdit ?
                <Button
                  variant="danger"
                  type="submit"
                  className="w-100 mx-auto"
                  style={{ maxWidth: "290px" }}
                >
                  सेव्ह करा आणि प्रोफाइल ला जा
                </Button> :
                <Button
                  variant="danger"
                  type="submit"
                  disabled={disable}
                  className="w-100 mx-auto"
                  style={{ maxWidth: "290px" }}
                >
                  फोटो सेव्ह करा आणि पुढे जा
                </Button>
              }
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  </>
  )
}

export default UploadImage;