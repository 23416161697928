import actionTypes from './profile.actionTypes';

const restartProfileResponse = () => ({
    type: actionTypes.RESTART_PROFILE_RESPONSE
});

const loading = () => ({
    type: actionTypes.LOADING
});

const loadProfileSuccess = (profile) => ({
    type: actionTypes.LOAD_PROFILE_SUCCESS,
    payload: profile
});

const loadProfileFailure = (errorMessage) => ({
    type: actionTypes.LOAD_PROFILE_FAILURE,
    payload: errorMessage
});

const updateProfileSuccess = (profile) => ({
    type: actionTypes.UPDATE_PROFILE_SUCCESS,
    payload: profile
});

const updateProfileFailure = (errorMessage) => ({
    type: actionTypes.UPDATE_PROFILE_FAILURE,
    payload: errorMessage
});

export default {
    restartProfileResponse,
    loading,
    loadProfileSuccess,
    loadProfileFailure,
    updateProfileSuccess,
    updateProfileFailure,
}