import ProfileService from '../../../services/profile.service';
import actions from './profile.actions';

export const loadUserProfile = () => (dispatch) => {

    dispatch(actions.loading());

    ProfileService.loadUserProfile()
        .then(response => {
            dispatch(actions.loadProfileSuccess(response.data));
        })
        .catch(error => {
            dispatch(actions.loadProfileFailure(error.response.data));
        });
}

export const updateUserProfile = (data) => (dispatch) => {
    dispatch(actions.loading());

    ProfileService.updateUserProfile(data)
        .then(response => {
            dispatch(actions.updateProfileSuccess(response.data));
        })
        .catch(error => {
            dispatch(actions.updateProfileFailure(error.response.data));
        });
};

export const registerUserProfile = (data, props) => (dispatch) => {
    dispatch(actions.loading());

    ProfileService.updateUserProfile(data)
        .then(response => {
            dispatch(actions.updateProfileSuccess(response.data));
            props.history.push("/payment");
        })
        .catch(error => {
            dispatch(actions.updateProfileFailure(error.response.data));
            props.history.push("/sorry");
        });
};