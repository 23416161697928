import React, {useState, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const user = useSelector(state => state.user)
    const [isAuthenticated, setIsAuthenticated] = useState(null)  
    useEffect(() => {
      let token = localStorage.getItem('user-token');
          if(token){
              let tokenExpiration = jwtDecode(token).exp;
              let dateNow = new Date();
              if(tokenExpiration < dateNow.getTime()/1000){
                  setIsAuthenticated(false)
              }else{
                  setIsAuthenticated(true)
              }
          } else {
             setIsAuthenticated(false)
          }
      // eslint-disable-next-line
    }, [user]);
  
    if(isAuthenticated === null){
      return <></>
    }
  
    return (
      <Route {...rest} render={props =>
        !isAuthenticated ? (
          <Redirect to='/login'/>
        ) : (
          <Component {...props} />
        )
      }
      />
    );
  };
  
  export default PrivateRoute;
