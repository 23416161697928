export default {
    RESTART_PROFILE_RESPONSE: 'RESTART_PROFILE_RESPONSE',
    LOADING: 'LOADING',

    LOAD_PROFILE_SUCCESS: 'LOAD_PROFILE_SUCCESS',
    LOAD_PROFILE_FAILURE: 'LOAD_PROFILE_FAILURE',

    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',
}


