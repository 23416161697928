import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import jwtDecode from 'jwt-decode';

const MenuList = [
  {
      title: "होम",
      url: "/"
  },
  {
      title: "आमच्या विषयी",
      url: "/about"
  },
  {
      title: "संपर्क",
      url: "/contact"
  },
  {
      title: "माझे प्रोफाइल",
      url: "/login"
  },
  {
      title: "नोंदणी",
      url: "/register"
  },
];

const LoggedInMenuList = [
  {
      title: "होम",
      url: "/"
  },
  {
      title: "आमच्या विषयी",
      url: "/about"
  },
  {
      title: "संपर्क",
      url: "/contact"
  },
  {
      title: "माझे प्रोफाइल",
      url: "/my-profile"
  },
  {
      title: "डॅशबोर्ड",
      url: "/dashboard",
      role: 'admin'
  },
  {
      title: "उपवधू",
      url: "/bride-home",
      role: 'admin'
  },
  {
      title: "उपवर",
      url: "/groom-home",
      role: 'admin'
  },
  {
      title: "सर्व नोंदणी",
      url: "/all-registrations",
      role: 'admin'
  },
  {
      title: "सर्व नोंदणी (Excel)",
      url: "/users-excel",
      role: 'admin'
  },
  {
      title: "पोस्ट",
      url: "/addresses",
      role: 'admin'
  },
];

const NavigationBar = () => {
  const [clicked, setClicked] = useState(false);
  const clickHandler = () => {
    setClicked(!clicked);
  };

  const user = useSelector(state => state.user)
  const [isAuthenticated, setIsAuthenticated] = useState(null)  
  useEffect(() => {
    console.log('user');
    console.log(user);
    let token = localStorage.getItem('user-token');
    if(token){
        let tokenExpiration = jwtDecode(token).exp;
        let dateNow = new Date();
        if(tokenExpiration < dateNow.getTime()/1000){
            setIsAuthenticated(false)
        }else{
            setIsAuthenticated(true)
        }
    } else {
        setIsAuthenticated(false)
    }
    // eslint-disable-next-line
  }, [user]);

  let menuList = "";

  if(isAuthenticated != null) {
    if(isAuthenticated) {
      menuList = LoggedInMenuList.map(({ url, title }, index) => {
        return (
          <li key={index} onClick={clickHandler}>
            <Nav.Link as={NavLink} exact to={url} activeClassName="active">
              {title}
            </Nav.Link>
          </li>
        );
      });
    } else {
      menuList = MenuList.map(({ url, title }, index) => {
        return (
          <li key={index} onClick={clickHandler}>
            <Nav.Link as={NavLink} exact to={url} activeClassName="active">
              {title}
            </Nav.Link>
          </li>
        );
      });
    }
  } else {
    menuList = MenuList.map(({ url, title }, index) => {
      return (
        <li key={index} onClick={clickHandler}>
          <Nav.Link as={NavLink} exact to={url} activeClassName="active">
            {title}
          </Nav.Link>
        </li>
      );
    });
  }

  return (
    <Navbar collapseOnSelect expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          LingayatYavatmal.com
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
          <Nav>{menuList}</Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
