import actionTypes from './profile.actionTypes';
import initialState from './profile.initialState';

const profileReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.RESTART_PROFILE_RESPONSE:
            return {
                ...state,
                isProfileLoading: false,
                userProfile: null,
                profileErrorMessage: null
            }
        case actionTypes.LOADING:
            return {
                ...state,
                isProfileLoading: true,
                userProfile: null,
                profileErrorMessage: null
            };
        case actionTypes.LOAD_PROFILE_SUCCESS:
            return {
                ...state,
                isProfileLoading: false,
                userProfile: payload
            };
        case actionTypes.LOAD_PROFILE_FAILURE:
            return {
                ...state,
                isProfileLoading: false,
                profileErrorMessage: payload
            };
        case actionTypes.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                isProfileLoading: false,
                userProfile: payload
            };
        case actionTypes.UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                isProfileLoading: false,
                profileErrorMessage: payload
            };
        default:
            return state;
    }
};

export default profileReducer;