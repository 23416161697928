import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import Loader from "../../shared-components/loader";
import apiClient from "../../helpers/apiClient";
import ProfileService from "../../services/profile.service";
import toasty from "../../utilities/toasty";
import { convertToDevanagariDate } from "../../helpers/utility";
import { convertToDevanagariTime } from "../../helpers/utility";
import Urls from "../../constants/urls";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.error = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const ProfileOverview = (props) => {

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  const { id } = useParams();

  useEffect(() => {
    apiClient().get("/user-details/" + id)
        .then((response) => {
          setUser(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
  }, [id]); 

  async function displayRazorpay() {
    setLoading(true);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      toasty('कृपया थोड्या वेळाने प्रयत्न करा', 'error');
      props.history.push('/my-profile');
      setLoading(false);
      return;
    }

    if(user !== null) {
      if(user.razorpay_order_id !== null) {
        const options = {
          key: Urls.RAZORPAY_API_KEY,
          amount: "40000",
          currency: "INR",
          name: "वीरशैव हितसंवर्धक मंडळ, यवतमाळ",
          description: "वधु-वर ऑनलाईन परिचय मेळावा २०२४ नोंदणी",
          order_id: user.razorpay_order_id,
          handler: function (response) {
            let rzResponse = {};
            rzResponse.razorpay_payment_id = response.razorpay_payment_id;
            rzResponse.razorpay_order_id = response.razorpay_order_id;
            rzResponse.razorpay_signature = response.razorpay_signature;
            
            ProfileService.verifyPayment(rzResponse)
              .then((res) => {
                setLoading(false);
                props.history.push("/thank-you");
              })
              .catch((error) => {
                setLoading(false);
                props.history.push("/sorry");
              });
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } else {
        ProfileService.createOrder()
      .then((orderData) => {
        const options = {
          key: Urls.RAZORPAY_API_KEY,
          amount: "40000",
          currency: "INR",
          name: "वीरशैव हितसंवर्धक मंडळ, यवतमाळ",
          description: "वधु-वर ऑनलाईन परिचय मेळावा २०२४ नोंदणी",
          order_id: orderData.data.data.order_id,
          handler: function (response) {
            let rzResponse = {};
            rzResponse.razorpay_payment_id = response.razorpay_payment_id;
            rzResponse.razorpay_order_id = response.razorpay_order_id;
            rzResponse.razorpay_signature = response.razorpay_signature;
            ProfileService.verifyPayment(rzResponse)
              .then((res) => {
                setLoading(false);
                props.history.push("/thank-you");
              })
              .catch((error) => {
                setLoading(false);
                props.history.push("/sorry");
              });
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      })
      .catch((error) => {
        // console.log(error.response.data);
      });
      }
    } else {
      alert("कृपया थोड्या वेळाने प्रयत्न करा");
    }
  }

  return (
    <>
    <PageTitle title="प्रोफाइल" align="center" />
    {loading && <Loader />}
    {!loading &&
    <section>
      <Container>
        <Row className="justify-content-center">
          <Col xl={9}>
            <div className="bg-white p-4 mb-3 vh_instructions">
              <h3>दिलेली माहिती तपासा व पेमेंट करा</h3>
            </div>

            <div className="vh-form">
                <Row className="mb-3">
                <div className="user-profile">
                    <div className="user-image">
                      <div className="user-no">&nbsp;</div>
                      {user.photo != undefined ? (
                        <img
                          src={`${Urls.IMAGE_BASE_URL}/${user.photo}`}
                          className="img-fluid"
                          alt="profile"
                        />
                      ) : (
                        ""
                      )}
                      <h2>
                        {user.last_name} {user.title}
                        {user.first_name}
                      </h2>
                    </div>
                    <div className="user-details">
                      <div className="user-no">&nbsp;</div>
                      <div className="vh-flex user-name">
                        <p className="vh-flex-title">नाव</p>
                        <p className="vh-flex-value">
                          {user.last_name} {user.title}
                          {user.first_name}{" "}
                          {user.middle_name}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">जन्म ता./वेळ</p>
                        <p className="vh-flex-value">
                          {user.dob != undefined
                            ? convertToDevanagariDate(user.dob)
                            : ""}
                          |{user.day_time}{" "}
                          {user.birth_time != undefined
                            ? convertToDevanagariTime(
                                user.birth_time
                              )
                            : ""}{" "}
                          मि.|जन्मगाव: {user.birth_place}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">&nbsp;</p>
                        <p className="vh-flex-value">
                          उंची: {user.height_foot}.
                          {user.height_inch}" ।{" "}
                          {user.varna} । रक्तगट:{" "}
                          {user.blood_group}{" "}
                          {user.divorced === "true"
                            ? "| घटस्फोटित"
                            : ""}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">शिक्षण</p>
                        <p className="vh-flex-value">
                        {user.profession} {user.education}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">नो./व्यवसाय</p>
                        <p className="vh-flex-value">
                          {user.occupation}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">अपेक्षा:</p>
                        <p className="vh-flex-value">
                          {user.expectations} &nbsp; वार्षिक
                          उत्पन्न: {user.annual_income}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">भाऊ विवाहित</p>
                        <p className="vh-flex-value">
                          {user.married_brothers} &nbsp; अविवाहित:{" "}
                          {user.unmarried_brothers} / बहीण विवाहित:{" "}
                          {user.married_sisters} &nbsp; अविवाहित:
                          {user.unmarried_sisters}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">वडिलांचे नाव</p>
                        <p className="vh-flex-value">
                          {user.parent_first_name}{" "}
                          {user.parent_middle_name}{" "}
                          {user.parent_last_name}
                          {user.parent_occupation != ""
                            ? "(" + user.parent_occupation + ")"
                            : ""}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">पत्ता</p>
                        <p className="vh-flex-value">
                          {user.parent_address} मो.
                          {user.parent_mobile}
                          {user.parent_annual_income != ""
                            ? " वार्षिक उत्पन्न: " + user.parent_annual_income
                            : ""}
                        </p>
                      </div>

                      <div className="vh-flex">
                        <p className="vh-flex-title">मामकूळ</p>
                        <p className="vh-flex-value">
                          {user.mama_first_name}{" "}
                          {user.mama_middle_name}{" "}
                          {user.mama_last_name},{" "}
                          {user.mama_address}, मो.
                          {user.mama_mobile}
                        </p>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row className="mb-3">
                <div className="vh_post_details">
                    <h3>माहिती पुस्तिका खाली दिलेल्या पत्यावर पाठवली जाईल</h3>
                    <p>नाव: {user.post_last_name}{" "}{user.post_first_name}{" "}{user.post_middle_name}<br/>पत्ता: {user.post_address}<br/>{user.post_district === "महाराष्ट्रा बाहेरील" ? '' : (user.post_district + ' - ')}{user.post_pincode}<br/> 
मोबाइल नं.: {user.post_mobile}</p>
                    </div>
                </Row>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>नोटिस</Form.Label>
                  <div></div>
                  <Form.Text className="text-muted">
                    नोंदणी फार्म मध्ये लिहिलेली माहिती पूर्णपणे खरी आहे. माहिती
                    चुकीची आढळल्यास त्याकरिता पालक स्वत: जबाबदार राहतील, मंडळ
                    जबाबदार राहणार नाही.
                  </Form.Text>
                </Form.Group>

                <Button
                  variant="danger"
                  onClick={displayRazorpay}
                  className="w-100 mx-auto"
                  style={{ maxWidth: "290px" }}
                >
                  पेमेंट करा
                </Button>
                </div>
          </Col>
        </Row>
      </Container>
    </section>
    }
  </>
  )
}

export default ProfileOverview;