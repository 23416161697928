import React from "react";
import NavigationBar from "./NavigationBar";

const Header = () => {
  return (
    <>
      <div className="topbar">
          <p>वीरशैव हितसंवर्धक मंडळ, यवतमाळ</p>
      </div>
      <NavigationBar />
    </>
  );
};

export default Header;
