import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const PageTitle = (props) => {
  return (
    <section className="page-title">
      <Container>
        <Row>
          <Col>
            <h3 style={{textAlign: `${props.align}`}}>{props.title}</h3>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PageTitle;
