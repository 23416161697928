import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Home from '../pages/Home';
import About from '../pages/info/About';
import Contact from '../pages/info/Contact';
import Login from '../pages/Login';
import Registration from '../pages/Registration';
import MyProfile from '../pages/MyProfile';
import NotFound from '../pages/NotFound';
import ThankYou from '../pages/payment/ThankYou';
import Sorry from '../pages/payment/Sorry';
import ScrollToTop from '../helpers/scroll-to-top';
import TermsConditions from '../pages/info/TermsConditions';
import PrivacyPolicy from '../pages/info/PrivacyPolicy';
import Pricing from '../pages/info/Pricing';
import PrivateRoute from './PrivateRoute';
import UploadImage from '../pages/uploadImage';
import EditDetails from '../pages/editDetails';
import ProfileOverview from '../pages/profileOverview';

const Routes = () => {
    return (
        <>
            <Router>
                <ScrollToTop />
                <Header />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Registration} />
                    <Route path="/thank-you" component={ThankYou} />
                    <Route path="/sorry" component={Sorry} />
                    <Route path="/pricing" component={Pricing} />
                    <Route path="/terms-conditions" component={TermsConditions} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <PrivateRoute exact path='/my-profile' component={MyProfile} />
                    <PrivateRoute exact path='/upload-image/:id' component={UploadImage} />
                    <PrivateRoute exact path='/edit-details/:id' component={EditDetails} />
                    <PrivateRoute exact path='/profile-overview/:id' component={ProfileOverview} />
                    <Route component={NotFound} />
                </Switch>
                <Footer />
            </Router>
            <ToastContainer theme="dark" />
        </>
    );
}

export default Routes;