import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import PageTitle from "../components/PageTitle";

const NotFound = () => {
  return (
    <>
      <PageTitle title="क्षमस्व!" align="center" />
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="success-container text-center">
                <img src="https://lingayatyavatmal.com/assets/img/failed.png" alt="Not found" />
                <h4 className="section-title">क्षमस्व!</h4>
                <p>
                  <b>आपण शोधताय तो वेबपेज उपलब्ध नाही</b>
                </p>
                <Link to="/" className="btn btn-danger">
                होम पेज ला जा
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NotFound;
