import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import PageTitle from "../../components/PageTitle";

const Sorry = () => {
  return (
    <>
      <PageTitle title="क्षमस्व!" align="center" />
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="success-container text-center">
              <img src="https://lingayatyavatmal.com/assets/img/failed.png" alt="Sorry" />
                <h4 className="section-title">क्षमस्व!</h4>
                <p><b>नोंदणी फी आमच्या पर्यंत पोहचली नाही</b></p>
                <p>कृपया <Link to="my-profile">"माझे प्रोफाइल"</Link> वरती जाऊन पेमेंट करा. तेव्हाच आपली नोंदणी स्वीकारण्यात येईल. आपल्या सहकार्यासाठी धन्यवाद.</p>
                <Link to="/my-profile" className="btn btn-danger">तुमचा प्रोफाइल इथे पाहा</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Sorry;
