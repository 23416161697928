import UserService from "../../../services/user.service";
import actions from './user.actions';

export const registerUser = (data, props) => (dispatch) => {
    dispatch(actions.restartAuthResponse());
    dispatch(actions.loading());

    UserService.registerUser(data)
        .then(response => {
            localStorage.setItem('user-token', response.data.data.token);

            dispatch(actions.registrationSuccess(response.data));

            props.history.push("/upload-image/" + response.data.data.id);
            dispatch(actions.restartAuthResponse());
        })
        .catch(error => {
            dispatch(actions.registrationFailure(error.response.data));
        });
};

export const userLogin = (credentials, props) => (dispatch) => {
    dispatch(actions.restartAuthResponse());
    dispatch(actions.loading());

    UserService.loginUser(credentials)
        .then(response => {
            localStorage.setItem('user-token', response.data.data.token);

            dispatch(actions.loginSuccess(response.data));

            props.history.push("/my-profile");
            dispatch(actions.restartAuthResponse());

        })
        .catch(error => {
            dispatch(actions.loginFailure(error.response.data));
        });
}

export const userLogout = (props) => (dispatch) => {
    dispatch(actions.restartAuthResponse());
    UserService.logoutUser()
        .then((response) => {
            localStorage.removeItem('user-token');
            dispatch(actions.logoutSuccess(response.data));
            dispatch(actions.restartAuthResponse());
            props.history.push("/");
        })
        .catch(error => {
            dispatch(actions.logoutFailure(error.response.data));
        });
}


export const clearUserAuthState = () => (dispatch) => {
    dispatch(actions.restartAuthResponse());
}