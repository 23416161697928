import React, {useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Form,
  Button
} from "react-bootstrap";
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../redux/reducers/user/user.thunk";
import PageTitle from "../components/PageTitle";
import { displayErrorMessages } from "../helpers/diplayError";
import Loader from "../shared-components/loader";

const Login = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onChange" });

  const dispatch = useDispatch();
  const { user, errorMessage } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const userP = useSelector(state => state.user)
  const [isAuthenticated, setIsAuthenticated] = useState(null)  
  useEffect(() => {
    let token = localStorage.getItem('user-token');
        if(token){
            let tokenExpiration = jwtDecode(token).exp;
            let dateNow = new Date();
            if(tokenExpiration < dateNow.getTime()/1000){
                setIsAuthenticated(false)
            }else{
                setIsAuthenticated(true)
            }
        } else {
           setIsAuthenticated(false)
        }
    // eslint-disable-next-line
  }, [userP]);

  if(isAuthenticated === null){
    return <></>
  }

  if(isAuthenticated) {
    props.history.push('my-profile');
  }

  const onSubmit = (data) => {
    setLoading(true);
    clearAuthErrDiv();
    dispatch(userLogin(data, props));
  };

  const clearAuthErrDiv = () => {
    let authErr = document.querySelector("#authErr");
    authErr.innerHTML = "";
  };

  const successMessage = (successMessage) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<div class="alert alert-success">' +
            " " +
            successMessage +
            "</div>",
        }}
      />
    );
  };

  return (
    <>
      <PageTitle title="लॉगिन" align="center" />
      { loading && <Loader /> }
      <section>
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div id="authErr"></div>
              <div className="bg-white">
                {user != null
                  ? successMessage(user.message)
                  : errorMessage != null
                  ? displayErrorMessages(
                      errorMessage.message,
                      document.getElementById("authErr")
                    )
                  : errorMessage}
              </div>
              <Form onSubmit={handleSubmit(onSubmit)} className="vh-form">
                <h2 className="form-title">स्वागत आहे</h2>
                <Form.Group className="mb-3" controlId="username">
                  <Form.Label>
                    तुमचे नाव <span className="vh-asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="उदा. आशिष"
                    {...register("username", {
                      required: true,
                      // pattern: /^[\u0900-\u097F ,-/()]+$/,
                      pattern: /^[\u0900-\u097F ]+$/,
                    })}
                  />
                  <p className="vh-error">
                    {errors.username?.type === "required" && "नाव आवश्यक आहे"}
                  </p>
                  <p className="vh-error">
                    {errors.username?.type === "pattern" &&
                      "फक्त मराठी स्वीकारली जाईल"}
                  </p>
                </Form.Group>

                <Form.Group className="mb-3" controlId="mobile">
                  <Form.Label>
                    तुमचा WhatsApp नं. <span className="vh-asterisk">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="उदा. १२८३९२८३९२"
                    {...register("mobile", {
                      required: true,
                      pattern: /^[\u0966-\u096F]+$/,
                      maxLength: 10,
                    })}
                  />
                  <p className="vh-error">
                    {errors.mobile?.type === "required" &&
                      "मोबाइल नं. आवश्यक आहे"}
                  </p>
                  <p className="vh-error">
                    {errors.mobile?.type === "pattern" &&
                      "फक्त मराठी अंक स्वीकारले जाईल"}
                  </p>
                  <p className="vh-error">
                    {errors.mobile?.type === "maxLength" &&
                      "कृपया वैध मोबाइल नं. टाईप करा"}
                  </p>
                </Form.Group>

                <input type="hidden" id="password" name="password" value="vmhy" />

                <Button variant="danger" type="submit" className="w-100">
                  लॉगिन
                </Button>
                <div className="mt-3 vh-already">
                  <p>
                    अद्याप नोंदणी केलेली नाही?{" "}
                    <Link to="/register">येथे नोंदणी करा</Link>
                  </p>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Login;
