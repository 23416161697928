import actionTypes from './user.actionTypes';

const restartAuthResponse = () => ({
    type: actionTypes.RESTART_AUTH_RESPONSE
});

const loading = () => ({
    type: actionTypes.LOADING
});

const registrationSuccess = (data) => ({
    type: actionTypes.REGISTRATION_SUCCESS,
    payload: data
});

const registrationFailure = (errorMessage) => ({
    type: actionTypes.REGISTRATION_FAILURE,
    payload: errorMessage
});

const loginSuccess = (credentials) => ({
    type: actionTypes.LOGIN_SUCCESS,
    payload: credentials
});

const loginFailure = (errorMessage) => ({
    type: actionTypes.LOGIN_FAILURE,
    payload: errorMessage
});

const logoutSuccess = (user) => ({
    type: actionTypes.LOGOUT_SUCCESS,
    payload: user
});

const logoutFailure = (errorMessage) => ({
    type: actionTypes.LOGOUT_FAILURE,
    payload: errorMessage
});

export default {
    restartAuthResponse,
    loading,
    registrationSuccess,
    registrationFailure,
    loginSuccess,
    loginFailure,
    logoutSuccess,
    logoutFailure
}