import moment from "moment";

export const convertToDevanagariDate = (dateString) => {
    let dateParts = dateString.split("-");

    let day = convertToDevanagari(dateParts[2]);
    let month = convertToDevanagari(dateParts[1]);
    let year = convertToDevanagari(dateParts[0]);

    let devanagariDate = day + '/' + month + '/' + year;

    return devanagariDate;
}

export const convertToDevanagariTime = (timeString) => {
    let H = +timeString.substr(0, 2);
    let h = H % 12 || 12;
    timeString = h + timeString.substr(2, 3);

    let timeParts = timeString.split(":");
    let hrs = convertToDevanagari(timeParts[0]);
    let mins = convertToDevanagari(timeParts[1]);

    let devanagariTime = hrs + '.' + mins;
    return devanagariTime;
}

export const convertToDevanagari = (number) => {
    let devanagariDigits = {
        '0': '०',
        '1': '१',
        '2': '२',
        '3': '३',
        '4': '४',
        '5': '५',
        '6': '६',
        '7': '७',
        '8': '८',
        '9': '९'
    };

    let converted_number = number.toString().replace(/[0123456789]/g, function (s) {
        return devanagariDigits[s];
    });

    return converted_number;
}

export const convertDate = (string) => {
    let date = moment.utc(string).local().format('YYYY-MM-DD');
    let devnagariDate = convertToDevanagariDate(date);
    return devnagariDate;
}